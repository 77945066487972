import { BaseModel } from '../base-model.class';
import { TranslatedProperty } from '@/models/shared/translated-property.class';

export default class RoomType extends BaseModel {
  name: TranslatedProperty = new TranslatedProperty();
  description: TranslatedProperty = new TranslatedProperty();
  locations = [];
  price = 0;
  imageId = '';
  imagePath = '';
  capacity = '';
  groundSurface = '';
  facilities = [];
  class = false;
  shape = false;
  computerRoom = false;
  theaterStyle = false;
}
