
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { format, addDays } from 'date-fns';
import { User } from '@/models/users/user.class';
import MeetingRoomBooking from '@/models/facilities/meeting-room-booking.class';
import { translateApi } from '@/utils/translate-api';
import { UseCurrentLocaleMixin } from '@/mixins/use-current-locale.mixin';
import RoomType from '@/models/facilities/room-type.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import Location from '@/models/locations/location.class';
import { BookingStatuses } from '@/enums/bookin-statuses.enum';
import { UpdateBooking } from '@/store/modules/bookings.module';
import { Invoice } from '@/models/invoices/invoice.class';
import { BModal } from 'bootstrap-vue';
import MeetingRoomBookingAdds from '@/models/facilities/meeting-room-booking-adds.class';
import { PaginatedResponse } from '../models/shared/paginated-response.interface';

const bookingsModule = namespace('bookings');
const roomTypesModule = namespace('roomTypes');
const locationsModule = namespace('locations');
const invoicesModule = namespace('invoices');
const authModule = namespace('auth');

@Component({
  components: {},
  filters: {
    translateApi,
  },
})
export default class BookingsData extends Mixins(UseCurrentLocaleMixin) {
  @bookingsModule.Action('updateBooking')
  updateBooking!: (payload: UpdateBooking) => Promise<MeetingRoomBooking>;

  @bookingsModule.Action('approveBookingOffer')
  aproveBookingOffer!: (payload: {
    booking: MeetingRoomBooking;
    customerReference: string;
  }) => Promise<Invoice>;

  @bookingsModule.Action('fetchBookingOffer')
  fetchBookingOffer!: (payload: MeetingRoomBooking) => Promise<string>;

  @bookingsModule.Action('fetchBookingsByUserId')
  fetchBookingsByUserId!: (userId: string) => Promise<MeetingRoomBooking[]>;

  @invoicesModule.Action('FETCH_ONE')
  fetchInvoiceById!: (invoiceId: string) => Promise<Invoice>;

  @invoicesModule.Action('fetchInvoicePdf')
  fetchInvoicePdf!: (invoiceId: string) => Promise<string>;

  @bookingsModule.Getter('bookings')
  bookings!: MeetingRoomBooking[];

  @roomTypesModule.Action('fetch')
  fetchRoomTypes!: () => Promise<PaginatedResponse<RoomType>>;

  @roomTypesModule.Getter('roomTypes')
  roomTypes!: PaginatedResponse<RoomType>;

  @locationsModule.Action('FETCH_UNPAGINATED')
  fetchLocations!: (payload?: FetchAllParams) => Promise<Location[]>;

  @locationsModule.Getter('ALL')
  locations!: Location[];

  @authModule.Getter('authenticatedUser')
  authenticatedUser!: User;

  $refs!: Vue['$refs'] & {
    rejectOfferModal: BModal;
    addPoNumberModal: BModal;
  };

  rejectMessage = '';

  PoNumber = '';

  currentBooking = new MeetingRoomBooking();

  async created() {
    await this.fetchBookingsByUserId(this.authenticatedUser._id as string);
    await this.fetchRoomTypes();
    await this.fetchLocations();
  }

  isBookingWaitingForPayment(status: string) {
    return BookingStatuses.WaitingPayment === status;
  }

  bookingStatusClasses(status: string) {
    // Define the classes based on booking.status
    return {
      pending: status === BookingStatuses.New,
      rejected: status === BookingStatuses.RequestRejected,
      waitingCustomer: status === BookingStatuses.WaitingForCustomer,
      expired: status === BookingStatuses.QuotationExpired,
      paid: status === BookingStatuses.Paid,
      completed: status === BookingStatuses.Completed,
      canceled: status === BookingStatuses.Canceled,
    };
  }
  getExpiredDate(expiryDate): string {
    return format(new Date(expiryDate), 'dd/MM/yyyy');
  }

  getBookingsWithRoomType() {
    return (
      this.bookings &&
      this.bookings.map(booking => {
        let bookingRoomType = new RoomType();
        if (this.roomTypes.docs) {
          bookingRoomType = this.roomTypes.docs.find(
            roomType => roomType._id === booking.roomTypeId,
          ) as RoomType;
        }

        return {
          ...booking,
          bookingRoomType: bookingRoomType,
        };
      })
    );
  }

  typeCateringOptions(catering) {
    const caterings = {
      noCatering: `${this.$t('facilities.noCatering')}`,
      coffee: `${this.$t('facilities.coffee')}`,
      breakfast: `${this.$t('facilities.breakfast')}`,
      lunch: `${this.$t('facilities.lunch')}`,
    };

    return caterings[catering];
  }

  getDate(date) {
    return format(new Date(date), 'dd/MM/yyyy');
  }

  getLocationsNames(locationsIds) {
    const locations = locationsIds?.map(locationId =>
      this.locations.find(location => location._id === locationId),
    );

    return (
      locations &&
      locations.length &&
      locations.map(location => location?.name[this.$i18n.locale])?.join(', ')
    );
  }

  isShowInvoiceSettingsButtons(status: string) {
    return BookingStatuses.WaitingForCustomer === status;
  }

  isShowInvoiceButton(status: string) {
    return (
      BookingStatuses.WaitingPayment === status ||
      BookingStatuses.Paid === status ||
      BookingStatuses.Completed === status
    );
  }

  isHidden(status) {
    return BookingStatuses.Canceled === status;
  }

  onFetchBookingOffer(selectedBookingId: string) {
    const booking = this.bookings.find(booking => booking._id === selectedBookingId);

    if (booking) {
      delete booking.priceOfferTimeStamp;

      this.fetchBookingOffer(booking).then(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.click();
        URL.revokeObjectURL(link.href);
      });
    }
  }

  async onFetchBookingInvoice(invoiceId: string) {
    const pdf = await this.fetchInvoicePdf(invoiceId);
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.target = '_blank';
    link.click();
    URL.revokeObjectURL(link.href);
  }

  async onUpdateBooking(booking: MeetingRoomBooking, status: string) {
    const changedBooking = {
      ...booking,
      status: status,
    };

    await this.updateBooking({
      id: booking._id as string,
      booking: changedBooking as MeetingRoomBooking,
    });
  }
  onApproveOffer(booking: MeetingRoomBooking) {
    this.currentBooking = booking;
    this.$refs.addPoNumberModal.show();
  }

  async approveOffer() {
    const response = await this.aproveBookingOffer({
      booking: this.currentBooking,
      customerReference: this.PoNumber,
    });

    if (response && typeof response._id === 'string') {
      this.currentBooking.invoiceId = response._id;
      await this.onUpdateBooking(this.currentBooking, BookingStatuses.WaitingPayment);
      await this.fetchBookingsByUserId(this.authenticatedUser._id as string);
    }
  }

  onOpenRejectOfferModal(booking: MeetingRoomBooking) {
    this.currentBooking = booking;
    this.$refs.rejectOfferModal.show();
  }
  onRefuseOffer() {
    this.currentBooking.rejectMessage = this.rejectMessage;
    this.onUpdateBooking(this.currentBooking, BookingStatuses.RequestRejected);
  }
  getPriceExVat(adds: MeetingRoomBookingAdds[], roomDiscount: number): number {
    let totalPrice = 0;

    adds.forEach((add, index) => {
      const { amount, price } = add;
      const totalAmount = amount * price;
      const applyDiscount = index === 0 && roomDiscount !== 0;
      // in case of bookings there are only 2 lines the first line will always be the room which can have discount
      // catering is the second line and never can have discount
      // Refactoring tip: put the discount in the adds if in the future the structure should change, but
      // this is not likely to happen!
      const discount = applyDiscount ? totalAmount / (100 / roomDiscount) : 0;

      totalPrice += totalAmount - discount;
    });

    return totalPrice;
  }

  resetPoNumber() {
    this.PoNumber = '';
  }
}
