export enum BookingStatuses {
  QuotePending = 'quote pending',
  RequestRejected = 'rejected',
  WaitingForCustomer = 'waiting for customer',
  QuotationExpired = 'quotation expired',
  WaitingPayment = 'waiting for payment',
  Paid = 'paid',
  Completed = 'completed',
  Canceled = 'canceled',
  New = 'new',
}
